@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=Montserrat");

@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");

@font-face {
  font-family: "Geist";
  src: url("/public/fonts/Geist-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geist Mono";
  src: url("/public/fonts/GeistMono-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

html {
  overflow: hidden;
}

body {
  min-height: 100vh;
}

#root {
  height: 100dvh;
  height: 100vh;
}

body {
  margin: 0;
  font-family: "Geist", "Geist Mono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("/public/img/apex-background.png") center / cover no-repeat;
  backdrop-filter: blur(10px);
}

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari, etc.) */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.Toastify__toast--success {
  background: #212627 !important;
}

.Toastify__progress-bar {
  background-color: #077368 !important;
}
.Toastify__toast-icon svg {
  fill: #077368 !important;
}
