.Toastify__toast {
  max-height: 60px !important;
  width: 100% !important;
  background: linear-gradient(135deg, #2c2c2c 0%, #1a1a1a 100%) !important;
  border: 1px solid rgba(121, 121, 121, 0.7) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
  display: flex !important;
  align-items: center !important;
  padding: 5px 20px !important;
  color: #fff !important;
  font-family: "Geist Mono" !important;
  font-size: 16px !important;
}

.Toastify__progress-bar {
  height: 0px !important;
}

.Toastify__progress-bar--bg {
  height: 0px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
